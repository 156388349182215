<template>
  <b-modal
    id="creditClassDetailModal"
    centered
    title="Chi tiết học phần"
    size="xl"
    body-class="position-static"
    hide-footer
    @shown="onShown"
  >
    <div class="row">
      <div class="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
        <div class="card">
          <div class="card-body">
            <h3>
              Thông tin học phần
            </h3>
            <div class="info-container">
              <ul class="list-unstyled">
                <li class="mb-1 mt-2">
                  <span class="fw-medium me-1">
                    Tên học phần:
                  </span>
                  <strong>
                    {{ infoDetail.subjectName }}
                  </strong>
                </li>
                <li class="mb-1 pt-1">
                  <span class="fw-medium me-1">
                    Mã học phần:
                  </span>
                  <strong>
                    {{ infoDetail.subjectCode }}
                  </strong>
                </li>
                <li class="mb-1 pt-1">
                  <span class="fw-medium me-1">
                    Khoa/bộ môn:
                  </span>
                  <strong>
                    {{ infoDetail.deparmentName }}
                  </strong>
                </li>
                <li class="mb-1 pt-1">
                  <span class="fw-medium me-1">
                    Số tín chỉ lý thuyết:
                  </span>
                  <strong>
                    {{ infoDetail.theoryCreditNum }}
                  </strong>
                </li>
                <li class="mb-1 pt-1">
                  <span class="fw-medium me-1">
                    Số tín chỉ thực hành:
                  </span>
                  <strong>
                    {{ infoDetail.practiceCreditNum }}
                  </strong>
                </li>
                <li class="mb-1 pt-1">
                  <span class="fw-medium me-1">
                    Số tín chỉ thực tập:
                  </span>
                  <strong>
                    {{ infoDetail.intershipCreditNum }}
                  </strong>
                </li>
                <li class="mb-1 pt-1">
                  <span class="fw-medium me-1">
                    Tổng số tiết:
                  </span>
                  <strong>
                    {{ infoDetail.lessonNum }}
                  </strong>
                </li>
                <li class="mb-1 pt-1">
                  <span class="fw-medium me-1">
                    Ngày bắt đầu:
                  </span>
                  <strong>
                    {{ infoDetail.startDate }}
                  </strong>
                </li>
                <li class="mb-1 pt-1">
                  <span class="fw-medium me-1">
                    Ngày kết thúc:
                  </span>
                  <strong>
                    {{ infoDetail.endDate }}
                  </strong>
                </li>
                <li class="mb-1 pt-1">
                  <span class="fw-medium me-1">
                    Số lượng đăng ký:
                  </span>
                  <strong>
                    {{ infoDetail.currentQty }}
                  </strong>
                </li>
                <li class="mb-1 pt-1">
                  <span class="fw-medium me-1">
                    Trạng thái:
                  </span>
                  <strong>{{ showCreditClassStatus(infoDetail.status) }}</strong>
                </li>
                <li class="mb-1 pt-1">
                  <span class="fw-medium me-1">
                    Giảng viên:
                  </span>
                  <strong>{{ infoDetail.teachers }}</strong>
                </li>
                <li class="mb-1 pt-1">
                  <span
                    v-if="infoDetail.status === 0"
                    class="fw-medium me-1"
                  >
                    Ngày thi dự kiến:
                  </span>
                  <span
                    v-else
                    class="fw-medium me-1"
                  >
                    Ngày thi chính thức:
                  </span>
                  <strong>{{ infoDetail.examinationStartDate === null ? 'Chưa có dữ liệu' : (infoDetail.examinationStartDate) }}</strong>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
        <div class="card">
          <div class="card-body">
            <h3>
              Danh sách học viên
            </h3>
            <div class="table-responsive mb-3">
              <div class="dataTables_wrapper dt-bootstrap5 no-footer">
                <b-row class="mt-1">
                  <b-col cols="12">
                    <vue-good-table
                      :columns="columns"
                      :rows="dataSources"
                      :pagination-options="paginationOptions"
                      :line-numbers="true"
                    >
                      <div
                        slot="emptystate"
                        style="text-align: center; font-weight: bold"
                      >
                        Không có bản ghi nào !
                      </div>
                      <template
                        slot="table-row"
                        slot-scope="props"
                      >
                      <span v-if="props.column.field === 'name'">
                        <b-media no-body>
                          <b-media-aside class="mr-1">
                            <b-img
                              :src="getIconFile(props.formattedRow[props.column.field])"
                              height="23"
                            />
                          </b-media-aside>
                          <b-media-body class="my-auto">
                            <h6 class="media-body mb-0">
                              {{ props.formattedRow[props.column.field] }}
                            </h6>
                          </b-media-body>
                        </b-media>
                      </span>
                        <!-- Column: Common -->
                        <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                      </template>

                      <!-- pagination -->
                      <template
                        slot="pagination-bottom"
                        slot-scope="props"
                      >
                        <div class="d-flex justify-content-between flex-wrap">
                          <div class="d-flex align-items-center mb-0">
                            <span class="text-nowrap"> Hiển thị 1 đến </span>
                            <b-form-select
                              v-model="itemsPerPage"
                              :options="itemsPerPageOptions"
                              class="mx-1"
                              @input="(value) => props.perPageChanged({ currentPerPage: value })"
                            />
                            <span class="text-nowrap">của {{ props.total }} bản ghi</span>
                          </div>
                          <div>
                            <b-pagination
                              :value="1"
                              :total-rows="props.total"
                              :per-page="itemsPerPage"
                              class="mt-1 mb-0"
                              @input="(value) => props.pageChanged({ currentPage: value })"
                            />
                          </div>
                        </div>
                      </template>
                    </vue-good-table>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-overlay
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
      :show="isLoading"
      no-wrap
    />
  </b-modal>
</template>

<script>
import {
  BCol, BFormSelect, BImg, BMedia, BMediaAside, BMediaBody, BModal, BOverlay, BPagination, BRow, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUser } from '@/auth/utils'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import { getIconFile } from '@/utils'
import { CREDIT_CLASS_STATUSES } from '@/const/status'

export default {
  name: 'CreditClassDetail',
  components: {
    BMedia,
    BImg,
    BMediaAside,
    BMediaBody,
    BModal,
    BCol,
    BRow,
    BFormSelect,
    BOverlay,
    BPagination,
    VueGoodTable,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    creditClassId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      currentPage: 1,
      itemsPerPage: 10,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Mã học viên',
          field: 'studentCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Mã học viên',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Họ tên học viên',
          field: 'studentName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Họ tên học viên',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Lớp cố định',
          field: 'creditClassCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Mã lớp độc lập',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ],
      infoDetail: {},
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'creditClassMedia/students',
      dataDetails: 'creditClassMedia/details',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
  },
  methods: {
    ...mapActions({
      getCreditClassDocuments: 'creditClassMedia/getStudentByCreditClass',
      getCreditClassDetail: 'creditClassMedia/getCreditClassDetail',
    }),
    async onShown() {
      await this.getDataSourcesFromStore()
    },
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getCreditClassDocuments({ creditClassId: this.creditClassId })
        await this.getCreditClassDetail({ creditClassId: this.creditClassId })
        if (this.dataDetails) {
          this.infoDetail = { ...this.dataDetails }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    getIconFile,
    showCreditClassStatus(id) {
      return (id) ? CREDIT_CLASS_STATUSES.find(status => status.value === id).label : ''
    },
  },
}
</script>
